import {React, useState} from "react";
import "./Experience.css";

import { BsBook, BsFillGearFill, BsFileEarmarkCheck  , BsFillFileEarmarkMedicalFill , BsPersonVcard, BsMap, BsPersonBoundingBox} from "react-icons/bs";

function Experience(){
    return(
        <div id="experienceContainer" className="fade-in">
            <div id="smallSections">
                <section  id="summary" class="container">
                    <h2><BsPersonVcard /> Summary</h2>
                    <div>
                        <img src="./assets/expPic.png" alt="profile pic" className="myPic" />
                        <p>Passionate and results-driven Software Developer with over 5 years of comprehensive experience in full-stack development. Seeking to leverage expertise in software engineering to contribute to innovative projects, particularly in autonomous systems, low-code development platforms, and online platforms.</p>
                    </div>
                    <p>Innovative Web Developer and ReactJS Specialist | Crafting Dynamic User Experiences | Passionate about Modern Web Technologies and Interactive Design</p>
                </section>
                <section id="education" class="container">
                    <h2><BsBook /> Education</h2>
                    <img src="./assets/njit.png" alt="NJIT" className="njit"/>
                    <p>Bachelor of Science in Computer Science</p>
                    <p>New Jersey Institute of Technology (NJIT), Newark, NJ, USA | Graduated May 2021 </p>
                </section>
                <section id="certificates" class="container">
                    <h2><BsFileEarmarkCheck /> Certificates</h2>
                    <div className="flexer">
                        <div>
                            <h4>Meta</h4>
                            <ul>
                                <li>Meta Front-End Developer</li>
                                <li>
                                    <a href="https://coursera.org/share/8f6b17b6c8df9e304a0afa9af5ff49fe" target="_blank">See the Certificate</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h4>HKUST</h4>
                            <ul>
                                <li>Front-End Web UI Frameworks and Tools: Bootstrap 4</li>
                                <li>
                                    <a href="https://coursera.org/share/36cb8b1af516b75c416cd1420f6cc3d0" target="_blank">See the Certificate</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h4>Vanderbilt University</h4>
                            <ul>
                                <li>Java for Androids</li>
                                <li>
                                    <a href="https://coursera.org/share/53196b65f36ba895207dcde684859101" target="_blank">See the Certificate</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section id="location" class="container">
                    <h2><BsMap /> Location</h2>
                    <p>Currently based in the Northern New Jersey / NYC Metropolitan Area. Willing to relocate. Willing to travel.</p>
                </section>
                <section id="clearance" class="container">
                    <h2><BsPersonBoundingBox /> Work Clearance</h2>
                    <p>I am a United States citizen and am fully authorized to work in the US. Consequently, I am available to work anywhere within the country and do not require sponsorship.</p>
                </section>
            </div>
            <div>
                <section id="experience" class="container">
                    <h2><BsFillFileEarmarkMedicalFill /> Professional Experience</h2>
                    <div id="jobContainer">
                        <div class="job">
                            <h3>Full-Stack Web Developer <img src="./assets/dndsLogo.png" alt="DNDS_Logo" /></h3>
                            <p>DN Development Studio LLC, Bayonne, NJ, US | August 2023 - Present</p>
                            <ul>
                                <li>Lead full-stack development projects focusing on both client-side and server-side coding.</li>
                                <li>Utilize technologies such as React, Node.js, JavaScript, CSS, and MySQL to develop and maintain high-quality web applications.</li>
                                <li>Implement best practices in database management and query optimization.</li>
                            </ul>
                        </div>
                        <div class="job">
                            <h3>Full-Stack Web Developer <img src="./assets/logo.png" alt="my_logo" /></h3>
                            <p>Freelancing & Self Employment, Bayonne, NJ, US | April 2018 - August 2023</p>
                            <ul>
                                <li>Developed and maintained web applications for various clients, enhancing functionality and user experience.</li>
                                <li>Specialized in using HTML5, SQL, JavaScript, PHP, Angular, React, and MongoDB.</li>
                                <li>Created robust, scalable database solutions and performed data integration using SQL and NoSQL databases.</li>
                            </ul>
                        </div>
                        <div class="job">
                            <h3>Officer of Research and Tech (Second Lieutenant) <img src="./assets/greekArmy.png" alt="greek_army" /></h3>
                            <p>Hellenic Army, Athens, Attica, Greece | March 2021 - May 2022</p>
                            <ul>
                                <li>Directed IT operations and development projects within the 25th Armored Brigade.</li>
                                <li>Developed and maintained mission-critical applications using Java, C++, and Python.</li>
                                <li>Enhanced team productivity and project management under high-pressure conditions.</li>
                            </ul>
                        </div>
                        <div class="job">
                            <h3>Front-end Web Developer <img src="./assets/one9Logo.png" alt="one_9_logo" /></h3>
                            <p>OneNine AI, Newark, NJ, US | August 2020 - December 2020</p>
                            <ul>
                                <li>Developed the front-end components of a data-mining application using Django and Python.</li>
                                <li>Collaborated with cross-functional teams to integrate user-facing elements with server-side logic.</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section id="skills" class="container">
                    <h2><BsFillGearFill /> Technical Skills</h2>
                    <ul>
                        <li>Languages:HTML, CSS, JavaScript, TypeScript, HTML, CSS, PHP, Python, SQL</li>
                        <li>Frameworks/Tools: ReactJs, Figma, Django, Bootstrap, Node.js, GitHub, Git</li>
                        <li>Databases: MySQL, MongoDB, Oracle</li>
                        <li>Others: Full Stack Development, Relational Database Design</li>
                    </ul>
                </section>
            </div>
        </div>
    );
}

export default Experience;
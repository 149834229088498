import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './404.css';
import { Link } from 'react-router-dom';

import { BsFillHouseFill, BsExclamationTriangle  } from "react-icons/bs";

const PageNotFound = () => {
  return (
    <div className='pnf'>
        <h2><BsExclamationTriangle /></h2>
        <h2>404 Page Not Found</h2>
        <p>Please click the home icon to go back to the home page.</p>
        <Link className='pnf-link' to={"/"}><BsFillHouseFill/></Link>
    </div>
  );
}

export default PageNotFound;